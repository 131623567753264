import { filterModelToWhereStringArray } from "./filter"
import { First, One, Rows } from "./carto"
import _ from "lodash"

export default new (class {
  getById = (cartodbId) => {
    return new Promise((resolve, reject) => {
      First(`
       SELECT * FROM ${process.env.REACT_APP_TREE_VIEW}
       WHERE cartodb_id = ${cartodbId}
      `)
        .then((ret) => {
          resolve(ret)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  chuteibokuSummaryQuery = (filter, polygon, rectangle) => {
    return new Promise((resolve, reject) => {
      let wheres = this.basicQuery(filter)
      wheres.push(this.addPolygonFilter(polygon))
      wheres.push(this.addRectangleFilter(rectangle))
      if (wheres.length === 0) {
        wheres = ["TRUE"]
      }

      let sql = `
      SELECT
        route AS rosen_no,
        name,
        SUM(CASE WHEN chuteiboku_height_rank = 'A' THEN 1 ELSE 0 END) AS rank_a,
        SUM(CASE WHEN chuteiboku_height_rank = 'B' THEN 1 ELSE 0 END) AS rank_b,
        SUM(CASE WHEN chuteiboku_height_rank = 'C' THEN 1 ELSE 0 END) AS rank_c,
        SUM(CASE WHEN chuteiboku_height_rank = 'D' THEN 1 ELSE 0 END) AS rank_d,
        COUNT(*) AS rosen_sum
      FROM ${process.env.REACT_APP_TREE_VIEW}
      WHERE type IN ('低木', '中木')
        AND chuteiboku_height_rank IS NOT NULL
        AND ${wheres.filter((v) => !!v).join(" AND ")}
      GROUP BY route, name
      ORDER BY route
      `

      Rows(sql)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  kobokuSummaryQuery = (filter, polygon, rectangle) => {
    return new Promise((resolve, reject) => {
      let wheres = this.basicQuery(filter)
      wheres.push(this.addPolygonFilter(polygon))
      wheres.push(this.addRectangleFilter(rectangle))
      wheres = wheres.filter((v) => !!v)
      if (wheres.length === 0) {
        wheres = ["TRUE"]
      }

      let sql = `
      SELECT
        route AS rosen_no,
        name,
        SUM(CASE WHEN koboku_perimeter_rank = 'A' THEN 1 ELSE 0 END) AS rank_a,
        SUM(CASE WHEN koboku_perimeter_rank = 'B' THEN 1 ELSE 0 END) AS rank_b,
        SUM(CASE WHEN koboku_perimeter_rank = 'C' THEN 1 ELSE 0 END) AS rank_c,
        SUM(CASE WHEN koboku_perimeter_rank = 'D' THEN 1 ELSE 0 END) AS rank_d,
        SUM(CASE WHEN koboku_perimeter_rank = 'E' THEN 1 ELSE 0 END) AS rank_e,
        SUM(CASE WHEN koboku_perimeter_rank = 'F' THEN 1 ELSE 0 END) AS rank_f,
        SUM(CASE WHEN koboku_perimeter_rank = 'G' THEN 1 ELSE 0 END) AS rank_g,
        SUM(CASE WHEN koboku_perimeter_rank = 'H' THEN 1 ELSE 0 END) AS rank_h,
        SUM(CASE WHEN koboku_perimeter_rank = 'I' THEN 1 ELSE 0 END) AS rank_i,
        SUM(CASE WHEN koboku_perimeter_rank = 'J' THEN 1 ELSE 0 END) AS rank_j,
        SUM(CASE WHEN koboku_perimeter_rank = 'K' THEN 1 ELSE 0 END) AS rank_k,
        COUNT(*) AS rosen_sum
      FROM ${process.env.REACT_APP_TREE_VIEW}
      WHERE type = '高木' AND koboku_perimeter_rank IS NOT NULL
        AND ${wheres.join(" AND ")}
      GROUP BY route, name
      ORDER BY route::int
    `

      Rows(sql, {})
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  gaikanShindanSummaryQuery = (filter, polygon, rectangle) => {
    return new Promise((resolve, reject) => {
      let wheres = this.basicQuery(filter)
      wheres.push(this.addPolygonFilter(polygon))
      wheres.push(this.addRectangleFilter(rectangle))
      if (wheres.length === 0) {
        wheres = ["TRUE"]
      }

      let sql = `
      SELECT
        route AS rosen_no,
        name,
        SUM(CASE WHEN kouboku_gaikan_shindan_rank = 'A' THEN 1 ELSE 0 END) AS rank_a,
        SUM(CASE WHEN kouboku_gaikan_shindan_rank = 'B' THEN 1 ELSE 0 END) AS rank_b,
        SUM(CASE WHEN kouboku_gaikan_shindan_rank = 'C' THEN 1 ELSE 0 END) AS rank_c,
        SUM(CASE WHEN kouboku_gaikan_shindan_rank = 'D' THEN 1 ELSE 0 END) AS rank_d,
        COUNT(*) AS rosen_sum
      FROM ${process.env.REACT_APP_TREE_VIEW}
      WHERE type = '高木' AND koboku_perimeter_rank IS NOT NULL
        AND ${wheres.filter((v) => !!v).join(" AND ")}
      GROUP BY route, name
      ORDER BY route::int
    `

      Rows(sql, {})
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  kikiShindanSummaryQuery = (filter, polygon, rectangle) => {
    return new Promise((resolve, reject) => {
      let wheres = this.basicQuery(filter)

      wheres.push(this.addPolygonFilter(polygon))
      wheres.push(this.addRectangleFilter(rectangle))

      if (wheres.length === 0) {
        wheres = ["TRUE"]
      }

      let sql = `
      SELECT
        route AS rosen_no,
        name,
        SUM(CASE WHEN kouboku_kiki_shindan_rank = 'A' THEN 1 ELSE 0 END) AS rank_a,
        SUM(CASE WHEN kouboku_kiki_shindan_rank = 'B' THEN 1 ELSE 0 END) AS rank_b,
        SUM(CASE WHEN kouboku_kiki_shindan_rank = 'C' THEN 1 ELSE 0 END) AS rank_c,
        COUNT(*) AS rosen_sum
      FROM ${process.env.REACT_APP_TREE_VIEW}
      WHERE type = '高木' AND koboku_perimeter_rank IS NOT NULL
        AND ${wheres.filter((v) => !!v).join(" AND ")}
      GROUP BY route, name
      ORDER BY route::int
    `

      Rows(sql, {})
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  basicQuery = (filter) => {
    let wheres = filter
      ? Object.keys(filter).flatMap((name) => {
          let fName = name

          // 名前の変更
          if (name === "hedge_count") {
            fName =
              "CASE WHEN hedge_count = '多数' THEN 9999 ELSE hedge_count::int END"
          } else if (name === '"tree_count') {
            fName =
              "CASE WHEN tree_count = '多数' THEN 9999 ELSE tree_count::int END"
          } else if (name === "teiboku_name") {
            return this._filterModelTeibokuToWhereStringArray(filter[name])
          } else if (name === "is_dead") {
            let vals = []
            if (filter[name].values.includes("(空白)")) {
              vals.push(`is_dead IS NULL`)
            }
            if (filter[name].values.includes("枯れ")) {
              vals.push(`is_dead = 1`)
            }
            if (vals.length === 0) {
              return []
            } else {
              return [`(${vals.join(" OR ")})`]
            }
          } else if (name === "is_management_plate") {
            let vals = []
            if (filter[name].values.includes("有り")) {
              vals.push("is_management_plate = 1")
            }
            if (filter[name].values.includes("無し")) {
              vals.push("is_management_plate IS NULL")
            }
            if (vals.length === 0) {
              return []
            } else {
              return [`(${vals.join(" OR ")})`]
            }
          } else if (name === "is_mytree") {
            let vals = []
            if (filter[name].values.includes("有り")) {
              vals.push("is_mytree = 1")
            }
            if (filter[name].values.includes("無し")) {
              vals.push("is_mytree is null")
            }
            if (vals.length === 0) {
              return []
            } else {
              return [`(${vals.join(" OR ")})`]
            }
          } else if (name === "photos") {
            let vals = []
            if (filter[name].values.includes("写真あり")) {
              vals.push("photos IS NOT NULL")
            }
            if (filter[name].values.includes("写真なし")) {
              vals.push("photos IS NULL")
            }
            if (vals.length === 0) {
              return []
            } else {
              return [`(${vals.join(" OR ")})`]
            }
          } else if (name === "diagnostic_pdf") {
            let vals = []
            if (filter[name].values.includes("PDFあり")) {
              vals.push("diagnostic_pdf IS NOT NULL")
            }
            if (filter[name].values.includes("PDFなし")) {
              vals.push("diagnostic_pdf IS NULL")
            }
            if (vals.length === 0) {
              return []
            } else {
              return [`(${vals.join(" OR ")})`]
            }
          }

          return filterModelToWhereStringArray(fName, filter[name])
        })
      : null
    return _.isEmpty(wheres) ? ["TRUE"] : wheres
  }

  tableQuery = (params, polygon, rectangle) => {
    return new Promise((resolve, reject) => {
      let wheres = this.basicQuery(params.request.filterModel)

      wheres.push(this.addPolygonFilter(polygon))
      wheres.push(this.addRectangleFilter(rectangle))

      let orderBy = params.request.sortModel.map((v) => {
        return `${v.colId} ${v.sort}`
      })
      if (orderBy.length === 0) {
        orderBy = ["id"]
      }

      let limit = params.request.endRow - (params.request.startRow ?? 0)
      let offset = params.request.startRow ?? 0

      Promise.all([
        this._getTableRows(
          wheres.filter((v) => !!v),
          orderBy,
          limit,
          offset
        ),
        this._getTableCount(wheres.filter((v) => !!v)),
      ])
        .then((values) => {
          params.success({
            rowData: values[0],
            rowCount: values[1],
          })
          resolve(values[1])
        })
        .catch((e) => {
          console.log(e)
        })
    })
  }

  selectLayerQuery = (filter, select, polygon, rectangle) => {
    let wheres = this.basicQuery(filter)

    wheres.push(this.addPolygonFilter(polygon))
    wheres.push(this.addRectangleFilter(rectangle))

    // 選択されたものだけを抽出するクエリを作成

    if (!select || Object.keys(select).length === 0) {
      wheres = ["FALSE"]
    } else {
      switch (select.field) {
        case "perimeter":
        case "width":
        case "height":
        case "hedge_length":
        case "pit_Number":
        case "tree_Number":
          wheres.push(`${select.field} = ${select.value}`)
          break
        case "date":
          wheres.push(`${select.field}::date = '${select.value}'::date`)
          break
        default:
          wheres.push(`${select.field} = '${select.value}'`)
      }
    }

    return `
      SELECT *, ST_Y(the_geom) AS latitude, ST_X(the_geom) AS longitude FROM ${
        process.env.REACT_APP_TREE_VIEW
      }
      WHERE ${wheres.filter((v) => !!v).join(" AND ")}
      `
  }

  exportQuery = (filter, sort, polygon, rectangle) => {
    let wheres = this.basicQuery(filter)
    wheres.push(this.addPolygonFilter(polygon))
    wheres.push(this.addRectangleFilter(rectangle))

    let orderBy =
      sort?.map((v) => {
        return `${v.colId} ${v.sort}`
      }) ?? []
    if (orderBy.length === 0) {
      orderBy = ["cartodb_id"]
    }
    return `
    SELECT *
    FROM ${process.env.REACT_APP_TREE_VIEW}
    WHERE ${wheres.filter((v) => !!v).join(" AND ")}
    ORDER BY ${orderBy.join(", ")}
    `
  }

  kokuLayerQuery = () => {
    return `SELECT * FROM koku`
  }

  gyoseikaiLayerQuery = () => {
    return `sELECT * FROM tokyo_to_gyoseikai`
  }

  roadGridLayerQuery = () => {
    return `SELECT * FROM tokyo_roadgrid`
  }

  addPolygonFilter = (polygon) => {
    if (!polygon || _.isEmpty(polygon)) {
      return null
    }
    console.log(polygon)
    const latLngs = [...polygon[0], _.first(polygon[0])]
    let l = `
    ST_Within(
      the_geom,
      ST_SetSRID(
        ST_PolygonFromText('POLYGON((${latLngs
          .map((ll) => `${ll.lng} ${ll.lat}`)
          .join(",")}
        ))')
        , 4326)
    )`
    console.log(l)
    return l
  }

  addRectangleFilter = (rectangle) => this.addPolygonFilter(rectangle)

  tableLayerQuery = (filter, polygon, rectangle) => {
    let wheres = this.basicQuery(filter)

    wheres.push(this.addPolygonFilter(polygon))
    wheres.push(this.addRectangleFilter(rectangle))

    return `
      SELECT *
      FROM ${process.env.REACT_APP_TREE_VIEW}
      WHERE ${wheres.filter((v) => !!v).join(" AND ")}
      `
  }

  labelLayerQuery = (filter) => {
    let wheres = this.basicQuery(filter)

    let sql = `
    SELECT
      cartodb_id,
      the_geom_webmercator,
      tree_id,
      citycode,
      cityname,
      route,
      name,
      variant,
      type_adjust,
      area_name,
      route_name,
      koboku_perimeter_rank,
      chuteiboku_height_rank,
      height || 'm' AS height,
      perimeter || 'cm' AS perimeter,
      width || 'm' AS width,
      teiboku_name,
      hedge_length || 'm' AS hedge_length,
      hedge_count || '本' AS hedge_count,
      CASE WHEN is_mytree = 1 THEN 'マイツリー' ELSE '' END is_mytree,
      CASE WHEN photos IS NOT NULL THEN '写真' ELSE '' END AS photos,
      CASE WHEN is_dead = 1 THEN '枯れ' ELSE '' END AS is_dead,
      CASE WHEN is_management_plate = 1 THEN '管理プレート' ELSE '' END AS is_management_plate,
      CASE WHEN diagnostic_pdf IS NOT NULL THEN '診断カルテ' ELSE '' END AS diagnostic_pdf,
      carbon_storage_kg || 'kg' AS carbon_storage_kg,
      carbon_absorption_kg || 'kg/年' AS carbon_absorption_kg,
      co2_absorption_kg || 'kg/年' AS co2_absorption_kg,
      co_absorption_kg || 'kg/年' AS co_absorption_kg,
      pm2_5_adsorption_kg || 'kg/年' AS pm2_5_adsorption_kg,
      no2_adsorption_kg || 'kg/年' AS no2_adsorption_kg,
      o3_adsorption_kg || 'kg/年' AS o3_adsorption_kg,
      so2_adsorption_kg || 'kg/年' AS so2_adsorption_kg,
      tree_canopy_interception_m3 || 'm3/年' AS tree_canopy_interception_m3,
      office,
      flower_month_ja,
      fruit_month_ja,
      autumnleaves_month_ja,
      remarks
    FROM ${process.env.REACT_APP_TREE_VIEW}
    WHERE ${wheres.join(" AND ")}
    `

    return sql
  }

  _filterModelTeibokuToWhereStringArray = (params) => {
    if (params.values.length === 0) {
      return ["FALSE"]
    }

    let v = "'{" + params.values.map((v) => `"${v}"`).join(",") + "}'"
    let val = " teiboku_array && " + v
    return [val]
  }

  _getTableRows = (wheres, order, limit, offset) => {
    return new Promise((resolve, reject) => {
      Rows(`
      SELECT * FROM ${process.env.REACT_APP_TREE_VIEW}
      WHERE ${wheres.join(" AND ")}
      ORDER BY ${order.join(",")}
      LIMIT ${limit} OFFSET ${offset}
      `)
        .then((ret) => {
          resolve(ret)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  _getTableCount = (wheres) => {
    return new Promise((resolve, reject) => {
      One(`
        SELECT COUNT(*) FROM ${process.env.REACT_APP_TREE_VIEW}
        WHERE ${wheres.join(" AND ")}
      `)
        .then((ret) => {
          resolve(ret)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
})()
