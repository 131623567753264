import React, { useEffect, useMemo, useState } from "react"
import { Box, Snackbar } from "@mui/material"
import { makeStyles } from "@mui/styles"
import PropTypes from "prop-types"
import ColumnsDef from "../../../../table/column"
import noImage from "../../../../../../resources/images/noimage.png"
import dayjs from "dayjs"
import "dayjs/locale/ja"
import "./databable.css"
import {
  ContentPaste as ContentPasteIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material"
dayjs.locale("ja")

const useStyles = makeStyles({
  root: {
    color: "inherit",
  },
  image: {
    width: "340px",
  },
})

const DialogDetailDetailContentDataTable = (props) => {
  const classes = useStyles()
  const columnDef = useMemo(() => ColumnsDef({}), [])
  const [copyText, setCopyText] = useState("リンクコピー")

  const onLinkCopy = (treeId) => {
    navigator.clipboard
      .writeText(
        window.location.host + window.location.pathname + "?tree_id=" + treeId
      )
      .then((res) => {
        setCopyText("コピーしました")
      })
      .catch((e) => {
        setCopyText("コピー失敗")
      })
  }

  return (
    <Box id="dialog_detail_datatable" className={classes.root}>
      {props.data.photos &&
        props.data.photos.split(",").map((v) => {
          return (
            <Box key={v}>
              <img
                className={classes.image}
                src={`https://images-tokyo.s3.ap-northeast-1.amazonaws.com/${v}`}
              />
            </Box>
          )
        })}
      {props.data.diagnostic_pdf && (
        <Box
          style={{ display: "flex", justifyContent: "end", fontSize: "14px" }}
        >
          <a target="_blank" href={props.data.diagnostic_pdf} rel="noreferrer">
            診断カルテ
            <OpenInNewIcon size="small" />
          </a>
        </Box>
      )}

      <table>
        {columnDef.flatMap((def) => {
          let d = props.data[def.field]
          //          console.log(d, def.field)
          if (!d) {
            return []
          }
          if (def.field === "photos") {
            return
          }
          if (def.field === "diagnostic_pdf") {
            return
          }

          switch (def.field) {
            case "date":
              d = dayjs(d).format("YYYY年M月D日(ddd)")
              break
            case "is_management_plate":
            case "is_mytree":
              d = d === 1 ? "有り" : "無し"
              break
            default:
              break
          }

          return (
            <tr key={def.field}>
              <th>{def.headerName}</th>
              <td>
                {d}{" "}
                {def.field === "tree_id" ? (
                  <a
                    href="#"
                    onClick={() => onLinkCopy(d)}
                    style={{ fontSize: "11px", color: "green" }}
                  >
                    {copyText}
                  </a>
                ) : (
                  ""
                )}
              </td>
            </tr>
          )
        })}
      </table>
    </Box>
  )
}

DialogDetailDetailContentDataTable.propTypes = {
  data: PropTypes.any,
  sx: PropTypes.object,
}

export default DialogDetailDetailContentDataTable
