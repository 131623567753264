import { ViewType } from "../index"

export const initialSortData = [{ colId: "tree_id", sort: "asc" }]
export const WindowMode = {
  Vertical: "vertical",
  Horizontal: "horizontal",
}
const RootViewDataState = {
  filterData: JSON.parse(window?.localStorage?.getItem("filter_data") ?? "{}"),
  sortData: JSON.parse(
    window?.localStorage?.getItem("sort_data") ??
      JSON.stringify(initialSortData)
  ),
  columnState: JSON.parse(
    window?.localStorage?.getItem("column_state") ?? null
  ),
  selectData: null,
  viewType: window?.localStorage.getItem("view_type")
    ? JSON.parse(window.localStorage.getItem("view_type"))
    : ["list"],
  mapLabel: null,
  mapZoom: null,
  refreshTime: null,
  mapSelectPolygon: null,
  mapSelectRectangle: null,
  windowMode: window?.localStorage.getItem("window_mode")
    ? window.localStorage.getItem("window_mode")
    : WindowMode.Vertical,
}

export default RootViewDataState
