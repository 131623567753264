import React, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
  useMemo,
} from "react"
import {
  Box,
  Typography,
  Modal,
  ClickAwayListener,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import UseRootViewData from "./data"
import FilterView from "./filter"
import MapView from "./map"
import TableView, { exportExcel as exportTableToExcel } from "./table"
import StandardView from "../../component/view"
import Header from "./header"
import GairojuManager from "../../manager/gairoju"
import DialogSummary from "./dialog/summary"
import DialogDetail from "./dialog/detail"
import { exportTableExcel } from "../../manager/excel"
import DialogLegends from "./dialog/legends"
import { DragHandleSharp as DragHandleSharpIcon } from "@mui/icons-material"
import { useLocation } from "react-router-dom"

const useStyles = makeStyles({
  root: {
    height: "100%",
  },
  hidden: {
    display: "none",
  },
})

export const ViewType = {
  Map: "map",
  List: "list",
}

const WindowMode = {
  Vertical: "vertical",
  Horizontal: "horizontal",
}

export const RootDataContext = React.createContext()

const RootView = (props) => {
  const classes = useStyles()

  const useRootData = UseRootViewData()
  const { state, setSelectedData } = useRootData

  const [openMap, setOpenMap] = useState(false)
  const [openSummaryDialog, setOpenDialogSummary] = useState(false)
  const [openLegendsDialog, setOpenLegendsDialog] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [hideList, setHideList] = useState(false)
  const [hideMap, setHideMap] = useState(false)
  const [mapViewType, setMapViewType] = useState(state.viewType)

  const [lastDragPosition, setLastDragPosition] = useState({ x: 0, y: 0 })
  const [topHorizontalWidthRate, setTopHorizontalWidthRate] = useState(0.5)
  const [topVerticalHeightRate, setTopVerticalHeightRate] = useState(0.5)
  const [topHorizontalWidth, setTopHorizontalWidth] = useState()
  const [topVerticalHeight, setTopVerticalHeight] = useState()

  const tableViewRef = useRef()
  const mapRef = useRef()
  const location = useLocation()
  const urlSearch = useMemo(() => location?.search, [location])
  const urlQuery = useMemo(
    () => (urlSearch ? new URLSearchParams(urlSearch) : null),
    [urlSearch]
  )

  useEffect(() => {
    setHideList(!(state.viewType ?? [ViewType.List]).includes(ViewType.List))
    setHideMap(!(state.viewType && state.viewType.includes(ViewType.Map)))
    setMapViewType(state.viewType)
  }, [state.viewType])

  useEffect(() => {
    window.addEventListener("resize", () => {
      topResize()
    })
  }, [])

  useEffect(() => {
    if (!urlQuery) {
      return
    }
    console.log("Update url query", urlQuery)
    let queryTreeId = urlQuery.get("tree_id")
    if (queryTreeId) {
      setDetailData({ tree_id: queryTreeId })
    }
  }, [urlQuery])

  const showDetailDialog = (data) => {
    console.log(data)
    setDetailData(data)
  }

  const showDetailDialogByCartodbId = (cartodbId) => {
    GairojuManager.getById(cartodbId).then((data) => {
      showDetailDialog(data)
    })
  }

  const onChangeViewMode = (e) => {
    setSelectedData({})
    setOpenMap(e.target.checked)
  }

  const onCloseSummary = (event, reason) => {
    switch (reason) {
      case "backdropClick":
        setOpenDialogSummary(false)
        break
      default:
        setOpenDialogSummary(false)
        break
    }
  }

  const onCloseLegends = () => {
    setOpenLegendsDialog(false)
  }

  const onCloseDetail = (event, updated) => {
    setDetailData(null)
    if (updated) {
      console.log(tableViewRef.current)
    }
  }

  const exportExcel = useCallback(() => {
    exportTableExcel(
      state.filterData,
      state.selectedData,
      state.mapSelectPolygon,
      state.mapSelectRectangle
    ).then()
  }, [
    state.filterData,
    state.mapSelectPolygon,
    state.mapSelectRectangle,
    state.selectedData,
  ])

  const [isDragging, setIsDragging] = useState(false)

  const onDragStart = (e) => {
    setIsDragging(true)
    setLastDragPosition({ x: e.clientX, y: e.clientY })
  }

  const onDragEnd = (e) => {
    if (!isDragging) {
      return
    }
    console.log("[DragEnd]", e)
    setIsDragging(false)

    mapRef.current?.invalidateSize()
  }

  const onDragging = (e) => {
    if (!isDragging) {
      return
    }
    if (state.windowMode === WindowMode.Horizontal) {
      if (e.clientY < 250 || e.clientY > window.innerHeight - 300) {
        return
      }
      // 縦方向の高さの割合を計算
      const newHeightRate = Math.max(
        0,
        Math.min(1, (e.clientY - 119) / (window.innerHeight - 119))
      )
      setTopVerticalHeightRate(newHeightRate)
    } else if (state.windowMode === WindowMode.Vertical) {
      if (e.clientX < 100 || e.clientX > window.innerWidth - 400) {
        return
      }
      // 横方向の幅の割合を計算
      const newWidthRate = Math.max(
        0,
        Math.min(1, e.clientX / window.innerWidth)
      )
      setTopHorizontalWidthRate(newWidthRate)
    }

    setLastDragPosition({ x: e.clientX, y: e.clientY })
  }

  useEffect(() => {
    topResize()
  }, [
    state.viewType,
    state.windowMode,
    topHorizontalWidthRate,
    topVerticalHeightRate,
  ])

  const topResize = useCallback(() => {
    console.log(
      "[Update size]",
      state.viewType,
      state.windowMode,
      window.innerWidth,
      window.innerHeight,
      topHorizontalWidthRate,
      topVerticalHeightRate
    )

    if (state.viewType.length === 2) {
      console.log("View both")
      if (state.windowMode === WindowMode.Horizontal) {
        let height = (window.innerHeight - 119) * topVerticalHeightRate
        console.log(
          "[Update size]",
          "new height",
          height,
          window.innerHeight,
          topVerticalHeightRate
        )
        setTopVerticalHeight(height)
        setTopHorizontalWidth(null)
      } else if (state.windowMode === WindowMode.Vertical) {
        let width = window.innerWidth * topHorizontalWidthRate
        console.log(
          "[Update size]",
          "new width",
          width,
          window.innerWidth,
          topHorizontalWidthRate
        )
        setTopHorizontalWidth(width)
        setTopVerticalHeight(null)
      }
    } else {
      setTopVerticalHeight(null)
      setTopHorizontalWidth(null)
    }
  }, [
    state.viewType,
    state.windowMode,
    window.innerWidth,
    window.innerHeight,
    topHorizontalWidthRate,
    topVerticalHeightRate,
  ])

  useEffect(() => {
    console.log("[Change size]", topHorizontalWidth, topVerticalHeight)
  }, [topHorizontalWidth, topVerticalHeight])

  return (
    <RootDataContext.Provider value={useRootData}>
      <StandardView>
        <Header
          disableHomeButton={true}
          onChangeViewMode={onChangeViewMode}
          onSummary={() => setOpenDialogSummary(true)}
          onLegends={() => setOpenLegendsDialog(true)}
          onExport={() => exportExcel()}
        />
        <FilterView openMap={openMap} />
        <Box
          className={classes.root}
          style={{
            display: "flex",
            flexDirection:
              state.windowMode === WindowMode.Horizontal ? "column" : "row",
          }}
        >
          <Box
            style={{
              flexGrow: !topVerticalHeight && !topHorizontalWidth ? 1 : null,
              height: topVerticalHeight,
              width: topHorizontalWidth,
              backgroundColor: "#eee",
              border: "1px solid #ddd",
            }}
            className={hideList ? classes.hidden : null}
          >
            <TableView showDetailDialog={showDetailDialog} ref={tableViewRef} />
          </Box>
          {(topHorizontalWidth || topVerticalHeight) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onMouseDown={onDragStart}
            >
              <DragHandleSharpIcon
                style={{
                  color: "#999",
                  transform:
                    state.windowMode === WindowMode.Vertical
                      ? "rotate(90deg)"
                      : null,
                }}
              />
            </Box>
          )}
          <Box
            style={{
              flexGrow: 1,
              position: "relative",
              border: "1px solid #ddd",
            }}
            className={hideMap ? classes.hidden : null}
          >
            <MapView
              viewType={mapViewType}
              enableNewWindowButton={false}
              showDetailDialogByCartodbId={showDetailDialogByCartodbId}
              onMapInitialized={(m) => (mapRef.current = m)}
            />
          </Box>
          {isDragging && (
            <Box
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
              onMouseMove={onDragging}
              onMouseUp={onDragEnd}
            ></Box>
          )}
          <Modal
            open={openSummaryDialog}
            onClose={onCloseSummary}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <DialogSummary onClose={onCloseSummary} />
          </Modal>

          <Modal open={!!detailData} onClose={onCloseDetail}>
            <DialogDetail
              data={detailData}
              treeId={detailData?.tree_id}
              onClose={onCloseDetail}
            />
          </Modal>

          <Modal
            open={openLegendsDialog}
            onClose={onCloseLegends}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <DialogLegends onClose={onCloseLegends} />
          </Modal>
        </Box>
      </StandardView>
    </RootDataContext.Provider>
  )
}

export default RootView
