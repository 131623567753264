import { makeStyles } from "@mui/styles"
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import * as L from "leaflet/dist/leaflet"
import "leaflet/dist/leaflet.css"
import PropTypes from "prop-types"
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material"
import { CircularProgress, IconButton, Typography } from "@mui/material"
import PlaceSearch from "./controls/placeSearch"
import { RootDataContext } from "../index"
import GairojuManager from "../../../manager/gairoju"
import { Rows } from "../../../manager/carto"
import LabelSelector from "./controls/labelSelector"
import Zoom from "./controls/zoom"
import CartoQueryLayer from "../../../manager/cartoQueryLayer"
import GairojuMaster from "../../../manager/gairoju"
import LayerSelector, { LayerType } from "./controls/layerSelector"
import "leaflet-rotate"
import "./map.css"

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
  },
  openInNew: {
    position: "absolute !important",
    right: "8px !important",
    bottom: "30px !important",
    backgroundColor: "white !important",
    zIndex: "2000 !important",
  },
  indicator: {
    position: "absolute",
    width: "30px !important",
    height: "30px !important",
    bottom: "210px",
    zIndex: 1000,
    left: "12px",
    color: "#803517 !important",
  },
  searchPlace: {
    position: "absolute",
    zIndex: "1000",
    left: "16px",
    bottom: "24px",
    width: "600px",
    backgroundColor: "white",
    maxWidth: "calc(100% - 300px)",
  },
  labelSelector: {
    position: "absolute !important",
    zIndex: "1000",
    right: "16px",
    bottom: "24px",
    width: "300px",
  },
  zoom: {
    position: "absolute",
    zIndex: "1000",
    top: "16px",
    left: "16px",
    backgroundColor: "white",
  },
})

const baseLayres = {
  道路台帳: L.tileLayer(
    "https://tokyo-g-mgmt.s3.ap-northeast-1.amazonaws.com/tiles/dourodaicho/merged_23-ku/{z}/{x}/{y}.png",
    { tileSize: 256, maxNativeZoom: 21, maxZoom: 22 }
  ),
  地理院地形図: L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
  街区地図: L.tileLayer(
    "https://api.mapbox.com/styles/v1/pacificspatial/ckwv1ijut1z4i15ldi8xot35b/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicGFjaWZpY3NwYXRpYWwiLCJhIjoiY2p1amlzYTVkMWh6NTN5bnF3cXZwa3F5YyJ9.3_50pwnF9CBMFhddvpE9-g",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
}

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

const cssTemplate = `
#layer {
  text-name: [%LABEL_COLUMN%];
  text-face-name: 'Unifont Medium';
  text-size: 18;
  text-fill: #e4e8df;
  text-label-position-tolerance: 0;
  text-halo-radius: 1;
  text-halo-fill: #621202;
  text-dy: -10;
  text-allow-overlap: true;
  text-placement: point;
  text-placement-type: dummy;
}
`

const MapView = (props) => {
  const classes = useStyles()
  const {
    state,
    setMapZoom,
    setMapSelectPolygon,
    setMapSelectRectangle,
    setRefreshTime,
  } = useContext(RootDataContext)
  const ref = useRef(null)
  const [showIndicator, setShowIndicator] = useState(false)
  const [isDrawing, setIsDrawing] = useState(false)
  const isDrawingRef = useRef(false)
  const [map, setMap] = useState(null)
  const [baseLayer, setBaseLayer] = useState(baseLayres["街区地図"])
  const [labelCss, setLabelCss] = useState(
    cssTemplate.replace("%LABEL_COLUMN%", "tree_id")
  )
  const [layerType, setLayerType] = useState(null)

  let loadCount = 0

  useEffect(() => {
    if (!map) {
      return
    }

    map.invalidateSize()
  }, [map, props.viewType, state.windowMode])

  useEffect(() => {
    if (!ref.current) {
      return
    }
    let m = L.map(ref.current, {
      maxZoom: 21,
      zoomControl: false,
      rotate: true,
      rotateControl: {
        closeOnZeroBearing: false,
      },
      ...props.mapOptions,
    })

    m.on("baselayerchange", (e) => {
      setBaseLayer(e.layer)
    }).on("zoomend", (e) => {
      setMapZoom(m.getZoom())
    })
    m.setView([35.6813706610132, 139.76727819517552], 13)
    L.control.layers(baseLayres).addTo(m)
    setMap(m)

    const drawControl = new L.Control.Draw({
      draw: {
        polygon: true,
        polyline: false,
        circle: false,
        rectangle: true,
        marker: false,
        circlemarker: false,
      },
    })
    m.on(L.Draw.Event.DRAWSTART, onDrawStarted)
    m.on(L.Draw.Event.CREATED, onDrawCreated)
    m.on(L.Draw.Event.DELETED, onDrawDeleted)
    m.on(L.Draw.Event.DRAWSTOP, onDrawStopped)
    m.addControl(drawControl)

    props.onMapInitialized && props.onMapInitialized(m)

    return () => {
      map?.remove()
      setMap(null)
      ref.current = null
      props.onMapInitialized && props.onMapInitialized(null)
    }
  }, [ref])

  const onDrawStarted = useCallback(() => {
    console.log("[Draw]", "start")
    isDrawingRef.current = true
  }, [])

  const onDrawCreated = useCallback((e) => {
    console.log("[Draw]", "created", e)
    switch (e.layerType) {
      case "polygon":
        setMapSelectPolygon(e.layer.getLatLngs())
        break
      case "rectangle":
        setMapSelectRectangle(e.layer.getLatLngs())
        break
      default:
        break
    }
  }, [])

  const onDrawDeleted = useCallback((e) => {
    console.log("[Draw]", "delete", e)
  }, [])

  const onDrawStopped = useCallback((e) => {
    console.log("[Draw]", "stop", e)
    isDrawingRef.current = false
  }, [])

  useEffect(() => {
    if (!map || !baseLayer) {
      return
    }
    baseLayer.addTo(map)

    return () => {
      map.removeLayer(baseLayer)
    }
  }, [map, baseLayer])

  const loadData = useCallback(() => {
    let sql = GairojuManager.selectLayerQuery(
      state.filterData,
      state.selectedData,
      state.mapSelectPolygon,
      state.mapSelectRectangle
    )
    if (!sql) {
      return
    }

    Rows(sql)
      .then((res) => {
        let latlngs = res.map((r) => [r.latitude, r.longitude])
        map.fitBounds(
          L.latLngBounds(latlngs, { padding: [50, 50], maxZoom: 19 })
        )
      })
      .catch((e) => {
        console.log("[Map]", "fit bounds query error", e)
      })
  }, [
    state.selectedData,
    state.filterData,
    state.mapSelectPolygon,
    state.mapSelectRectangle,
  ])

  useEffect(() => {
    if (!state.selectedData || !map) {
      return
    }
    loadData()
  }, [state.selectedData])

  const openNewWindow = () => {
    window.open(
      "/map",
      null,
      "width=300,toolbar=yes,menubar=yes,scrollbars=yes"
    )
  }

  const onTileLoadStart = () => {
    loadCount++
    indicatorStart()
  }

  const onTileLoaded = () => {
    loadCount--
    if (loadCount < 0) loadCount = 0
    if (loadCount === 0) {
      indicatorStop()
    }
  }

  useEffect(() => {
    console.log("[Map]", "is drawing update", isDrawing)
  }, [isDrawing])

  const onPointClicked = useCallback(
    (cartodbId) => {
      openDetailDialog(cartodbId)
    },
    [isDrawing, props]
  )

  const openDetailDialog = useCallback(
    (cartodbId) => {
      if (isDrawingRef.current) {
        return
      }
      props.showDetailDialogByCartodbId(cartodbId)
    },
    [props]
  )

  const indicatorStart = () => {
    setShowIndicator(true)
  }

  const indicatorStop = () => {
    setShowIndicator(false)
  }

  const onPlaceSelected = (place) => {
    map.setView(place.center, 17, {
      animate: true,
      pan: {
        duration: 1.5,
      },
    })
  }

  const onPointClickedFromTable = useCallback(
    (e) => {
      openDetailDialog(e.data["cartodb_id"])
    },
    [isDrawing]
  )

  return (
    <>
      {props.enableNewWindowButton && (
        <IconButton className={classes.openInNew} onClick={openNewWindow}>
          <OpenInNewIcon />
        </IconButton>
      )}
      <div className={classes.root} ref={ref}></div>
      <CartoQueryLayer
        map={map}
        cartoCss={`
      #layer {
    marker-width: 10;
    marker-fill: [fill_color];
    marker-fill-opacity: 0.75;
    marker-line-color: #FFFFFF;
    marker-line-width: 1;
    marker-line-opacity: 1;
    marker-placement: point;
    marker-type: ellipse;
    marker-allow-overlap: true;
  }        
        `}
        query={GairojuMaster.tableLayerQuery(
          state.filterData,
          state.mapSelectPolygon,
          state.mapSelectRectangle
        )}
        featureClickColumns={["cartodb_id"]}
        onFeatureClick={onPointClickedFromTable}
        onLoading={onTileLoadStart}
        onLoadFinished={onTileLoaded}
        zIndex={1000}
      />
      <CartoQueryLayer
        map={map}
        cartoCss={`#layer {
          marker-width: 12;
          marker-fill: [selected_fill_color];
          marker-fill-opacity: 0.75;
          marker-line-color: #FFFFFF;
          marker-line-width: 1;
          marker-line-opacity: 1;
          marker-placement: point;
          marker-type: ellipse;
          marker-allow-overlap: true;
        }`}
        query={GairojuMaster.selectLayerQuery(
          state.filterData,
          state.selectedData,
          state.mapSelectPolygon,
          state.mapSelectRectangle
        )}
        featureClickColumns={["cartodb_id"]}
        onFeatureClick={onPointClicked}
        onLoading={onTileLoadStart}
        onLoadFinished={onTileLoaded}
        zIndex={10010}
        hidden={!state.selectedData}
      />
      <CartoQueryLayer
        map={map}
        cartoCss={cssTemplate.replace("%LABEL_COLUMN%", state.mapLabel)}
        query={GairojuMaster.labelLayerQuery(
          state.filterData,
          state.mapSelectPolygon,
          state.mapSelectRectangle
        )}
        onLoading={onTileLoadStart}
        onLoadFinished={onTileLoaded}
        hidden={!state.mapLabel || state.mapZoom < 19}
        zIndex={20000}
      />
      <CartoQueryLayer
        map={map}
        cartoCss={`
        #layer {
          polygon-fill: #6d8eba;
          polygon-opacity: 0.3;
        }
        #layer::outline {
          line-width: 5;
          line-color: #6f7e72;
          line-opacity: 0.5;
        }
        #layer::labels {
          text-name: [koku_name];
          text-face-name: 'Unifont Medium';
          text-size: 18;
          text-fill: #333333;
          text-label-position-tolerance: 0;
          text-halo-radius: 1;
          text-halo-fill: #6F808D;
          text-dy: -10;
          text-allow-overlap: true;
          text-placement: point;
          text-placement-type: dummy;
        }
        `}
        query={GairojuMaster.kokuLayerQuery()}
        onLoading={onTileLoadStart}
        onLoadFinished={onTileLoaded}
        hidden={layerType !== LayerType.Koku}
        zIndex={1000}
      />
      <CartoQueryLayer
        map={map}
        cartoCss={`
        #layer {
          polygon-fill: #89ba6d;
          polygon-opacity: 0.3;
        }
        #layer::outline {
          line-width: 5;
          line-color: #6f7e72;
          line-opacity: 0.5;
        }
        #layer::labels {
          text-name: [cityname];
          text-face-name: 'Unifont Medium';
          text-size: 18;
          text-fill: #333333;
          text-label-position-tolerance: 0;
          text-halo-radius: 1;
          text-halo-fill: #6F808D;
          text-dy: -10;
          text-allow-overlap: true;
          text-placement: point;
          text-placement-type: dummy;
        }
        `}
        query={GairojuMaster.gyoseikaiLayerQuery()}
        onLoading={onTileLoadStart}
        onLoadFinished={onTileLoaded}
        hidden={layerType !== LayerType.Gyoseikai}
        zIndex={1000}
      />
      <CartoQueryLayer
        map={map}
        cartoCss={`
        #layer {
          polygon-fill: #ffffff;
          polygon-opacity: 0.01;
        }
        #layer::outline {
          line-width: 1;
          line-color: #ff0000;
          line-opacity: 0.5;
        }
        #layer::labels {
          text-name: [roadgrid];
          text-face-name: 'Lato Bold';
          text-size: 12;
          text-fill: #f81d1d;
          text-label-position-tolerance: 0;
          text-halo-radius: 1;
          text-halo-fill: #ffffff;
          text-dy: -15;
          text-allow-overlap: false;
          text-placement: interior;
          text-placement-type: dummy;
        }
        `}
        query={GairojuMaster.roadGridLayerQuery()}
        onLoading={onTileLoadStart}
        onLoadFinished={onTileLoaded}
        hidden={layerType !== LayerType.RoadGrid}
        zIndex={2000}
      />
      <PlaceSearch
        className={classes.searchPlace}
        onPlaceSelected={onPlaceSelected}
      />
      <LabelSelector className={classes.labelSelector} />
      <LayerSelector
        onChange={(e) => {
          console.log(e)
          setLayerType(e)
        }}
        sx={{
          position: "absolute",
          zIndex: "900",
          backgroundColor: "#fff",
          width: "120px",
          top: "72px",
          borderColor: "#969292",
          borderStyle: "solid",
          borderWidth: "1px",
          borderRadius: "8px",
          padding: "8px",
          fontSize: "12px",
          boxShadow: "1px 1px 3px #b8b8b8",
          right: "8px",
        }}
      />
      {showIndicator && (
        <CircularProgress className={classes.indicator} color="secondary" />
      )}
      <Zoom className={classes.zoom} map={map} />
    </>
  )
}

MapView.propTypes = {
  enableNewWindowButton: PropTypes.bool,
  showDetailDialogByCartodbId: PropTypes.func,
  history: PropTypes.any,
  match: PropTypes.any,
  location: PropTypes.any,
  mapOptions: PropTypes.any,
  viewType: PropTypes.any,
  onMapInitialized: PropTypes.func,
}

export default MapView
